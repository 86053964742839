@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.articles {
	@include widget;
	// &:not(.layout_3) {
	// 	gap: 60px;
	// }

	.article:not(h4) {
		text-decoration: none;
		&.restrain {
			// max-height: 400px;

			.article-content {
				overflow: hidden;
				-webkit-line-clamp: 6;
				display: -webkit-box;
				line-clamp: 6;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
			}
		}
	}

	.article-content {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 21px;
		margin-top: 20px;
	}

	&.layout_1,
	&.layout_3,
	&.layout_6 {
		.article:not(h4) {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.article-img {
				max-width: 100%;
				position: relative;

				img {
					max-width: 100%;
				}
				&:after {
					display: block;
					content: '';
					position: absolute;
					inset: 0;
					background: transparent;
				}
			}

			.article-meta {
				display: flex;
				gap: 12px;

				font-family: 'Roboto';
				font-style: normal;
				font-weight: 600;
				font-size: 10px;
				line-height: 10px;
				letter-spacing: 0.1em;
				text-transform: uppercase;

				span + span::before {
					content: '•';
					padding-right: 12px;
				}
			}

			.article-forward {
				display: none;
			}

			.article-title-header {
				display: none;
			}

			.article-tags {
				display: flex;
				gap: 10px;
				white-space: nowrap;
				flex-wrap: wrap;
			}

			h4.article {
				padding-bottom: 30px;
				margin-bottom: 10px;
				border-bottom: 1px solid rgba($dark_blue, 0.25);
			}

			&:hover {
				.article-img {
					&:after {
						background: linear-gradient(185.38deg, #162542 11.46%, transparent 95.7%);
					}
				}

				h4.article {
					border-bottom-color: $midnight_blue;
					position: relative;

					.article-forward {
						display: block;
						position: absolute;
						right: 0;
						height: 32px;
						bottom: -16px;
						background-color: white;
						padding-left: 20px;
					}
				}
			}
		}
	}

	&.layout_2 .related-articles {
		padding-top: 40px;
		// overflow-y: hidden;
		// overflow-x: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		column-gap: 20px;

		.article:not(h4) {
			flex: 1;
			min-width: 100%;
			display: flex;
			flex-direction: column;
			gap: 20px;

			.title {
				white-space: break-spaces;
			}

			.article-img {
				max-width: 100%;
				position: relative;

				img {
					max-width: 100%;
				}
				&:after {
					display: block;
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: transparent;
				}
			}

			.article-meta {
				display: flex;
				gap: 12px;

				font-family: 'Roboto';
				font-style: normal;
				font-weight: 600;
				font-size: 10px;
				line-height: 10px;
				letter-spacing: 0.1em;
				text-transform: uppercase;

				span + span::before {
					content: '•';
					padding-right: 12px;
				}
			}

			.article-forward {
				display: none;
			}

			.article-title-header {
				display: none;
			}

			.article-tags {
				display: flex;
				gap: 10px;
				white-space: nowrap;
				flex-wrap: wrap;
			}

			h4.article {
				padding-bottom: 30px;
				margin-bottom: 10px;
				border-bottom: 1px solid rgba($dark_blue, 0.25);
			}

			&:hover {
				.article-img {
					&:after {
						background: linear-gradient(185.38deg, #162542 11.46%, transparent 95.7%);
					}
				}

				h4.article {
					border-bottom-color: $midnight_blue;
					position: relative;

					.article-forward {
						display: block;
						position: absolute;
						right: 0;
						height: 32px;
						bottom: -16px;
						background-color: white;
						padding-left: 20px;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		&.layout_2 .related-articles {
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding-bottom: 50px;
			.article:not(h4) {
				display: flex;
				padding-top: 40px;
				border-top: 1px solid rgba($dark_blue, 0.25);
				gap: 40px;
				flex-direction: row;

				.article-img:after {
					max-height: 102px;
				}
				img {
					height: 102px;
				}

				.article-meta {
					display: none;
				}

				.article.title {
					flex: 1;
					border-bottom: 0 none;
					padding-bottom: 0;
					margin-bottom: 0;

					.article-forward {
						display: none;
					}
				}

				.article-forward {
					display: initial;
				}

				.article-title-header {
					font-family: 'Roboto';
					font-style: normal;
					font-weight: 700;
					font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.05em;
					text-transform: uppercase;
					display: flex;
					align-items: flex-end;
				}

				.article-tags {
					display: none;
				}

				.article-forward {
					height: 32px;
					align-self: center;
				}
				h4.article {
					display: flex;
					flex-direction: column;
					gap: 20px;
				}

				&:hover h4.article .article-forward {
					display: none;
				}
			}
		}
	}

	&.layout_3,
	&.layout_6 {
		.articles-horizontal {
			display: flex;
			gap: 40px;
			.article:not(h4) {
				width: 33%;

				img {
					max-width: 100%;
				}

				h4.article {
					white-space: pre-wrap;
				}
			}
		}
	}

	&.layout_6 {
		.articles-horizontal {
            padding: 60px 0;
			.article:not(h4) {
				min-width: 100%;
			}
		}
	}

    @include media-breakpoint-up(sm) {
		&.layout_6 {
			.articles-horizontal {
				.article:not(h4) {
					min-width: 50%;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		&.layout_6 {
			.articles-horizontal {
				.article:not(h4) {
					min-width: 33%;
				}
			}
		}
	}

    @include media-breakpoint-up(lg) {
		&.layout_6 {
			.articles-horizontal {
				.article:not(h4) {
					min-width: 25%;
				}
			}
		}
	}

	&.knowledge {
		.article {
			margin-bottom: 30px;
		}

		.knowledgeview-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.knowledgeview-actions {
				display: flex;
				gap: 10px;

				.toggle {
					border-radius: 20px;
					border: 0 none;
					background-color: white;
					cursor: pointer;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;

					&:first-child {
						display: none;
					}

					@include media-breakpoint-up(lg) {
						&:first-child {
							display: flex;
						}
					}

					img {
						margin-left: 1px;
					}

					.toggle-dark {
						display: none;
					}

					&:hover {
						background-color: $midnight_blue;

						.toggle-white {
							display: none;
						}
						.toggle-dark {
							display: initial;
						}
					}

					&.selected {
						background-color: $midnight_blue;

						.toggle-dark {
							display: initial;
						}

						.toggle-white {
							display: none;
						}

						&:hover {
							background-color: white;

							.toggle-white {
								display: initial;
							}
							.toggle-dark {
								display: none;
							}
						}
					}
				}
			}
		}

		.list {
			margin-bottom: -30px;
			.article:not(.title) {
				margin-bottom: 0;
				display: grid;
				grid-template-columns: 1fr 3fr 1.5fr 1fr;
				border-top: 1px solid #000000;
				padding: 20px 30px;

				&.list-header {
					border: 0 none;
					text-transform: uppercase;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					letter-spacing: 0.05em;
				}

				.article-img {
					display: none;
				}

				.article.title {
					border-bottom: 0 none;
					font-weight: 300;
					font-size: 24px;
					line-height: 30px;
					padding-bottom: 0px;
					margin-bottom: 0px;
					margin-top: -4px;
				}

				.article-tags {
					align-items: flex-start;
				}

				.article-type {
					font-family: 'Roboto';
					font-style: normal;
					font-weight: 600;
					font-size: 10px;
					line-height: 10px;
					letter-spacing: 0.1em;
					text-transform: uppercase;
				}

				&:not(.list-header):hover {
					background-color: $midnight_blue;
					color: white;
					.article.title {
						color: white;
					}

					.article-tags {
						.tag {
							color: $midnight_blue;
							background-color: white;
						}
					}
				}
			}
		}
	}

	.articles {
		padding-bottom: 0;

		.article:not(h4) {
			@include media-breakpoint-up(sm) {
				padding: 20px;
			}
			@include media-breakpoint-up(lg) {
				padding: 30px;
			}
			&:hover {
				background-color: $gray;

				h4.article {
					.article-forward {
						background-color: $gray;
					}
				}
			}
		}
	}
}

.gray {
	.articles {
		.article:not(h4) {
			&:hover {
				h4.article {
					.article-forward {
						background-color: $gray !important;
					}
				}
			}
		}
	}
}

.gray .tab-content {
	.articles {
		.article:not(h4) {
			&:hover {
				h4.article {
					.article-forward {
						background-color: white;
					}
				}
			}
		}
	}
}

// .story-article-main {
//     padding-right: 90px !important
// }

.story-article {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	padding-right: 75px;
	gap: 20px;
	padding-bottom: 200px;

	a {
		word-break: break-word;
	}
	p {
		margin: 30px 0;

		& + div {
			margin-top: 60px;
		}
	}

	.article-meta {
		display: flex;
		gap: 12px;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 10px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding: 30px 0 0px 0;

		span + span::before {
			content: '•';
			padding-right: 12px;
		}
	}

	.article-tags {
		display: flex;
		gap: 10px;
	}

	.article-navigation {
		display: flex;
		flex-direction: column;

		.article-navigation-item {
			display: flex;
			border-top: 1px solid rgba($dark_blue, 0.25);
			text-decoration: none;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			padding: 13px 0;
			gap: 30px;
			align-items: center;
			transition: background-color 0.3s linear, color 0.3s linear, padding 0.3s linear;

			.article-nav-index {
				font-weight: 500;
			}

			.article-nav-title {
				flex: 1;
			}

			&:hover {
				background: $midnight_blue;
				color: white;
				padding: 13px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		gap: 30px;
		.article-meta {
			padding-top: 40px;
		}
	}

	@include media-breakpoint-up(lg) {
		.article-meta {
			padding: 60px 0 30px 0;
		}
	}
}

.article-toggle {
	border-bottom: 1px solid rgba($dark_blue, 0.25);
	padding: 40px 60px;
	margin: 0;
	.toggle-header {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		.toggle-action {
			font-family: 'Klavika';
			font-style: normal;
			font-weight: 400;
			font-size: 30px;
			line-height: 24px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: rgba($dark_blue, 0.25);
		}
	}
}

.sticky-header {
	position: sticky;
	top: 0;
}

// .products-list {
//     .featured-product {
//         border: 3px solid $midnight_blue;
//     }
// }

.article-container {
	position: relative;
	contain: paint;
}

@include media-breakpoint-up(xl) {
	.article-container {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	// .story-article-main {
	// }
}

.related-content {
	position: absolute;
	background: white;
	overflow-y: visible;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	transform: translateX(calc(100% - 75px));
	border-left: 1px solid rgba($dark_blue, 0.25);
	display: grid;
	grid-template-columns: 75px 1fr;
	transition: transform 0.3s ease;

	.related-content-sidebar {
		border-right: 1px solid rgba($dark_blue, 0.25);
		display: flex;
		flex-direction: column;

		.action-button {
			cursor: pointer;
			border: 0 none;
			background-color: transparent;
			color: $midnight_blue;
			position: sticky;
			top: 20px;
			white-space: nowrap;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0px 30px 70px 15px;
			margin-top: 20px;

			.rotate-content {
				// transform-origin: top left;
				margin-top: 70px;
				transform: rotate(-90deg);
			}

			.button-icon {
				border: 2px solid $midnight_blue;
				min-height: 30px;
				min-width: 30px;
				padding-right: 2px;
				display: flex;
				justify-content: center;
				border-radius: 50%;
			}
		}
	}

	&.is-open {
		transform: translateX(calc(100% - 425px));

		.button-icon {
			transform: rotateZ(180deg);
			padding-right: 4px;
		}
	}

	.related-content-content {
		overflow-y: auto;
		max-width: 350px;

		.articles,
		.products-list {
			padding: 60px 30px 40px 60px;
		}
	}

	@include media-breakpoint-up(sm) {
		transform: translateX(calc(100% - 60px));
		grid-template-columns: 60px 1fr;

		.related-content-sidebar {
			.action-button {
				padding-right: 15px;
			}
		}

		.related-content-content {
			.articles,
			.products-list {
				padding-right: 0;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		transform: translateX(0);
		&.is-open {
			transform: translateX(0);
		}
		position: relative;
		transform: none;
		grid-template-columns: 1fr;
		.related-content-sidebar {
			display: none;
		}
	}
}
