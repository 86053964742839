@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.feature-statements {
	margin-top: 30px;
    @include media-breakpoint-up (md) {
        margin-top: 60px;
    }

    @include media-breakpoint-up (xl) {
        margin-top: 90px;
    }
	.feature-statement {
        opacity: 0;
		transform: translateY(50px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px 40px 40px 40px;
		gap: 20px;

		// background: linear-gradient(127.72deg, $midnight_blue 1%, rgba($midnight_blue, 0.5) 100%);

		border-left: 1px solid #ffffff;
		border-radius: 0px;

		.count {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 28px;
			/* identical to box height, or 140% */

			letter-spacing: 0.02em;
		}

		.description {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 21px;
		}
        &.animate-ease-up {
            transform: translateY(0);
            opacity: 1;
        }
        
	}

	@include media-breakpoint-up(lg) {
	
	}
}
