@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.popup-notice {
	p {
		@include roboto-light-intro;
		margin: 0;
	}

	.ms-Modal-scrollableContent {
		display: flex;
		flex-direction: column;
		row-gap: 25px;
	}

	.notice-disclaimer {
		@include media-breakpoint-up(lg) {
			padding: 20px 0px;
		}
	}
}

.notice {
	color: $light_blue;
	padding: 30px 40px;
	border: 1px solid $light_blue;

	@include media-breakpoint-up(lg) {
		padding: 30px 60px;
	}
}
