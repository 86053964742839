@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

@mixin mobile-menu-navigation {
	display: flex;
	flex-direction: column;
	gap: 40px;
	row-gap: 0;

	background-color: $midnight_blue;
	z-index: 1;

	.image-link {
		display: initial;
		visibility: visible;
	}

	> .navigation-item,
	> .language-selector-container {
		padding: 30px 30px;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			inset: 0 30px;
			border-top: 1px solid rgba(white, 0.6);
            pointer-events: none;
		}
	}

	.navigation-item.navigate {
		display: flex;
		// justify-content: space-between;
		padding: 0px 30px;
		& > a,
		& > span {
			padding: 30px 0;
		}

		&.sub {
			gap: 15px;
			align-items: center;
		}
		.navigate-forward {
			display: initial;
			height: 23px;
			margin-top: -5.5px;
			margin-bottom: -5.5px;

			&.reverse {
				transform: rotateY(180deg);
			}
		}
	}

	> .language-selector-container,
	> a:last-of-type:after {
		border-bottom: 1px solid rgba(white, 0.6);
	}
}

@include media-breakpoint-up(xs) {
	.container {
		padding-left: 30px;
		padding-right: 30px;

		.row {
			margin-left: -30px !important;
			margin-right: -30px !important;
			row-gap: 30px;

			& > * {
				padding-right: 30px !important;
				padding-left: 30px !important;
			}
		}
	}

	.header,
	.navigation-header {
		display: flex;
		color: white;
		justify-content: space-between;
		align-items: center;
		min-height: 75px;

		.logo {
			img {
				height: 28px;
			}
		}
	}

	.navigation-menu {
		cursor: pointer;
	}

	.navigation {
		display: none;

		.navigation-item {
			cursor: pointer;
			font-weight: bold;
			color: white;
			white-space: nowrap;
			text-decoration: none;
			font-weight: 600;
			font-size: 12px;
			line-height: 12px;
			text-transform: uppercase;

			a {
				text-decoration: none;
			}
		}

		& + .language-selector-container {
			display: none;
		}

		&.open {
			@include mobile-menu-navigation;

            .navigation-item {
                display: flex;
                gap: 15px;
                align-items: center;
                img {
                    height: 35px
                }
            }
		}
	}

	.navigation-container {
		.navigation {
			.image-link {
				display: none;
				visibility: hidden;
			}
		}
	}

	.navigation-container.is-open {
		// div:not(.right-navigation) {
		> .navigation {
			@include mobile-menu-navigation;
			position: fixed;
			inset: 0;
            overflow-y: auto;
		}

		.navigation-menu-icon {
			display: none;
		}

		.navigation-menu-close {
			position: relative;
			width: calc(33px / 2);
			height: 33px;

			&:before,
			&:after {
				position: absolute;
				content: ' ';
				height: 33px;
				width: 3px;
				background-color: #fff;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}

		.navigation-header {
			padding: 0px 30px;
		}
	}

	.footer {
		padding-top: 60px;
		padding-bottom: 60px;
		color: white;
		display: grid;
		grid-template:
			'logo navigation'
			'social navigation';

		.footer-navigation {
			grid-area: navigation;
		}

		.logo {
			grid-area: logo;
			display: flex;
			flex-direction: column;
			row-gap: 30px;
		}

		.social-media {
			grid-area: social;
			align-items: flex-end;
		}

		.navigation {
			display: flex;
			row-gap: 20px;
			flex-direction: column;
			width: 100%;
			text-align: right;
		}

		.logo {
			height: 30px;

			img {
				height: 30px;
			}
		}
	}

	.copyright {
		font-weight: lighter;
		font-size: 12px;
		white-space: nowrap;
	}

	.asset-summary {
		// gap: 50px;
		color: #7dc7f5;

		.asset {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			row-gap: 5px;

			.asset-title,
			.asset-source {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: 0.02em;
				text-transform: uppercase;
			}

			.asset-title {
				flex: 65%;
			}

			.asset-source {
				text-align: right;
				flex: 1;
			}

			.asset-amount {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 12px;
				column-gap: 5px;
				display: flex;
				justify-content: flex-end;
				flex: 35%;
			}

			.asset-indicator {
				&.negative {
					transform: rotateX(180deg);
				}
			}

			.asset-currency {
				font-weight: 100;
			}

			&:not(:first-child) {
				display: none;
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	.container {
		padding-left: 30px;
		padding-right: 30px;

		.row {
			margin-left: -30px !important;
			margin-right: -30px !important;

			& > * {
				padding-right: 30px !important;
				padding-left: 30px !important;
			}
		}

		// .container {
		// 	padding-left: 15px;
		//     padding-right: 15px;

		.row {
			margin-left: -15px !important;
			margin-right: -15px !important;

			& > * {
				padding-right: 15px !important;
				padding-left: 15px !important;
			}
		}
		// }
	}
}

// @include media-breakpoint-up(md) {
// 	.header {
// 	}
// }

@include media-breakpoint-up(lg) {
	.header {
		height: 100px;
		.navigation-menu {
			display: none;
		}
		.right-navigation {
			display: flex;
			column-gap: 40px;
			.navigation {
				justify-content: flex-end;
				align-items: center;
			}
		}
		.navigation {
			display: flex;
			position: initial;
			flex-direction: row;
			gap: 40px;
			row-gap: 0;
			align-items: center;

			.navigation {
				display: none;
			}

			.navigation-item {
				padding-top: 10px;
				padding-bottom: 10px;
				border-bottom: 3px solid transparent;

				.navigate-forward {
					display: none;
				}

				&:hover {
					color: $light_blue;
					border-bottom-color: $light_blue;
				}
				&.active {
					color: white;
					border-bottom-color: white;
				}
			}

			.navigation-mobile {
				display: none;
			}

			.language-selector-container + .container {
				display: none;
			}
			.footer {
				display: none;
			}
		}
	}

	.navigation {
		.navigation-item {
			transition: border-bottom-color 0.4s linear, color 0.4s linear;
			&:hover {
				color: $light_blue;
			}
			&.active {
				color: white;
			}
		}
	}

	.asset-summary {
		display: flex;
		justify-content: flex-end;
		text-align: right;
		gap: 50px;
		.asset {
			row-gap: 0px;
			flex-direction: column;
			align-items: flex-end;
			&:not(:first-child) {
				display: flex;
			}

			.asset-title,
			.asset-amount,
			.asset-source {
				flex: initial;
				font-size: 10px;
			}

			.asset-amount {
				font-family: 'Klavika';
				font-style: normal;
				font-weight: 400;
				font-size: 28px;
				line-height: 34px;
				gap: 10px;
				justify-content: flex-end;
			}
		}
	}

	.footer {
		padding-top: 40px;
		padding-bottom: 140px;

		grid-template:
			'logo navigation'
			'logo social';

		row-gap: 20px;

		.navigation {
			flex-direction: row;
			column-gap: 40px;
			justify-content: flex-end;
			align-items: center;
			height: 30px;
		}

		.social-media {
			justify-content: flex-end;

			.image-link {
				height: 30px;
				padding: 0px 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: background-color 0.4s linear;
				a path {
					transition: fill 0.4s linear;
				}
				&:hover {
					background-color: white;
					a path {
						fill: $midnight_blue;
					}
				}
			}
		}
	}
}
// @include media-breakpoint-up(xl) {
// 	.header {
// 	}
// }
// @include media-breakpoint-up(xxl) {
// 	.header {
// 	}
// }

.left-navigation .navigation .navigation-item:first-child {
	padding-left: 0px;
}

.navigation .navigation-item:last-child {
	padding-right: 0px;
}

.left-navigation .navigation,
.right-navigation .navigation {
	flex-wrap: wrap;
}

.language-selector {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;

	display: flex;
	gap: 10px;

	cursor: pointer;
}

.two-column-row-split {
	.top-left,
	.bottom-left,
	.right-column {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.top-left,
	.bottom-left {
		border-bottom: 1px solid rgba($dark_blue, 0.25);
	}

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template:
			'topleft right'
			'bottomleft right';

		.top-left,
		.bottom-left,
		.right-column {
			padding: 120px 90px;
		}

		.top-left,
		.bottom-left {
			padding-left: 0px;
		}

		.right-column {
			padding-right: 0px;
		}
		.top-left {
			grid-area: topleft;
		}

		.bottom-left {
			grid-area: bottomleft;
			border-bottom-width: 0px;
		}

		.right-column {
			grid-area: right;
			border-left: 1px solid rgba($dark_blue, 0.25);
		}
	}
}

.navigation-container > .navigation {
	align-items: center;

	& > * {
		width: 100%;
		@media (min-width: 576px) {
			max-width: 540px;
		}
		@media (min-width: 768px) {
			max-width: 720px;
		}
		@media (min-width: 992px) {
			max-width: 960px;
		}
		@media (min-width: 1200px) {
			max-width: 1140px;
		}
		@media (min-width: 1400px) {
			max-width: 1320px;
		}
	}
}


.share-button {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    outline: none;
    cursor: pointer;
    position: relative;

    .close-icon {
        display: none;
    }

    &.close {
        .close-icon {
            display: initial;
            position: absolute;
            right: 9px;
            top: 8px;
            background: $midnight_blue;
            height: 16px;
            width: 16px;

            &:before,
            &:after {
                position: absolute;
                content: ' ';
                height: 16px;
                width: 2px;
                background-color: white;
                transition: background-color 0.3s linear;
                border-radius: 2px;
            }
    
            &:before {
                transform: rotateZ(45deg);
            }
    
            &:after {
                transform: rotateZ(-45deg);
            }
        }
        
    }

    &.alternate.close {
        .close-icon {
            background-color: white;

            &:before,
            &:after {
                background-color: $midnight_blue;
            }

        }
    }
}

.share-dropdown-container.ms-Callout {
    border: 0 none;
    box-shadow: initial;
    color: $midnight_blue;
    .ms-Callout-main {
        background-color: transparent;
    }

    .share-dropdown {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        a {
            font-size: 18px;
            background-color: white;
            height: 32px;
            width: 32px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.alternate {
        background-color: transparent;
        color: white;
        .share-dropdown a {
            background-color: $midnight_blue;
        }
    }
}