@use '../../../styles/vars' as *;
@use '../../../styles/mixins' as *;

.nasdaq {
	font-family: 'Klavika';
	font-size: 80px;
	line-height: 94px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: $light_blue;
	flex: 1;

	@include media-breakpoint-up(lg) {
		flex: initial;
	}
	padding: 40px;
	border: 2px solid $light_blue;
	align-self: start;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

	.nasdaq-details {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* or 150% */

		letter-spacing: 0.03em;
		text-transform: uppercase;

		display: flex;
		flex-direction: column;
		gap: 10px;

		.nasdaq-details-small {
			font-size: 12px;
			line-height: 12px;
		}
	}

	.nasdaq-stats {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		display: flex;
		padding-top: 30px;
		justify-content: space-between;

		.nasdaq-stat-item {
			display: flex;
			flex-direction: column;

			span + span {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 18px;
			}

			& + .nasdaq-stat-item {
				margin-left: 40px;
				margin-right: 10px;
			}
		}
	}
}

.extra-content {
	display: flex;
	justify-content: flex-end;

	@include media-breakpoint-up(lg) {
		padding-top: 120px;
	}
}

.nasdaq-disclaimer {
    color: $light_blue;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 13px;
}
