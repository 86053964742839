@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-filters {
	display: flex;
	justify-content: space-between;

	.etp-filter {
		gap: 20px;
		align-items: flex-end;
	}

	@include media-breakpoint-up(xs) {
		.etp-filter {
			width: 100%;
			.ms-Dropdown-container {
				width: 100%;
			}
		}
		.etp-filter:not(:first-child) {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.etp-filter {
			width: initial;
			&:not(:first-child) {
				display: flex;
			}
		}
	}
}

.etps {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 30px;
	@include media-breakpoint-up(lg) {
		row-gap: 5px;
	}
	column-gap: 0px;
	max-width: 100%;
	margin-top: 40px;

	.etp {
		display: grid;
		cursor: pointer;
		background-color: white;
		text-decoration: none;
		color: $midnight_blue;
		padding: 30px;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		&:first-child {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			&:first-child {
				display: grid;
			}
			padding: 0px;
			grid-template-columns: 0.5fr 1.3fr 0.7fr 1fr 1fr 1.2fr 1.2fr;
		}
	}
}

.document-link {
	display: none;
	visibility: hidden;

	@include media-breakpoint-up(lg) {
		display: flex;
		visibility: visible;
		height: 100px;
		text-decoration: none;
		justify-content: space-between;
		align-items: center;
		padding: 40px;
		border: 1px solid white;
		font-family: 'Klavika';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
	}
}

.white {
	.document-link {
		border-color: $midnight_blue;
	}
}

.etp- {
	&logo,
	&name,
	&fee,
	&bloomberg,
	&isin,
	&currencies,
	&factsheet {
		display: flex;
		align-items: center;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 21px;
		text-transform: uppercase;

		&:nth-child(n + 3) {
			border-top: 1px solid rgba($dark_blue, 0.25);

			padding-top: 15px;
			padding-bottom: 15px;
		}

		&:nth-child(3) {
			border-top-color: $midnight_blue;
		}

		@include media-breakpoint-up(lg) {
			height: 100px;
			padding: 0px;
			font-size: 16px;
			line-height: 19px;

			&:nth-child(n + 3) {
				border-top: 0 none;

				padding-top: 0px;
				padding-bottom: 0px;
			}
		}

		&.grid-header {
			display: none;

			@include media-breakpoint-up(lg) {
				display: flex;
				cursor: default;
				height: 80px;
				font-family: 'Roboto';
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.05em;
			}
		}

		a {
			text-decoration: none;
		}
	}

	&logo {
		justify-content: flex-start;
		margin-bottom: 60px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0px;
			justify-content: center;
		}
		img {
			height: 64px;

			@include media-breakpoint-up(lg) {
				height: initial;
			}
		}
	}

	&name {
		font-family: 'Klavika';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		/* or 120% */

		display: flex;
		align-items: center;
		letter-spacing: 0.03em;

		margin-bottom: 60px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0px;
		}
	}

	&fee,
	&bloomberg,
	&isin,
	&currencies,
	&factsheet {
		display: flex;
		justify-content: space-between;
		&:before {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: 21px;
		}
	}

	&fee {
		&:before {
			content: 'FEE';
		}
	}

	&bloomberg {
		&:before {
			content: 'BLOOMBERG';
		}
	}

	&isin {
		&:before {
			content: 'ISN';
		}
	}

	&currencies {
		&:before {
			content: 'TRADING CURRENCIES';
		}
	}

	&factsheet {
		font-weight: 400;
		&:before {
			content: 'FACTSHEET';
		}

		a {
			display: flex;
			gap: 10px;
			@include media-breakpoint-up(lg) {
				width: 100%;
				justify-content: space-between;
				padding-right: 30px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&fee,
		&bloomberg,
		&isin,
		&currencies,
		&factsheet {
			&:before {
				content: initial;
			}
		}
	}
}

// .etp {
//     background-color: white;
//     color: $midnight_blue;
//     height: 100px;
//     padding-left: 30px;
//     padding-right: 30px;

//     a {
//         text-decoration: underline;
//     }
// }

.etp-document-list-container {
	& + .etp-document-list-container {
		margin-top: 60px;
	}

	.etp-document-list {
		margin-top: 10px;
		border-top: 1px solid $midnight_blue;

		.etp-document {
			display: grid;
			align-items: center;
			justify-items: space-between;
			column-gap: 15px;
			padding: 20px 0;
            grid-template-areas: 'icon name download'
                                    'icon type download';

			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			text-transform: uppercase;

            .document-icon {
                grid-area: icon
            }

            .document-name {
                grid-area: name
            }

            .document-type {
                grid-area: type
            }


			.link {
                grid-area: download;
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-decoration: none;
				justify-self: flex-end;

                span {
                    display: none;
                }
			}

			@include media-breakpoint-up(md) {
                column-gap: 30px;
                grid-template-areas: 'icon name type download';
				grid-template-columns: 50px 4fr 2fr 3fr;

                .link {
                    span {
                        display: initial;
                    }
                }
			}
		}

		.etp-document:not(:last-child) {
			border-bottom: 1px solid rgba($dark_blue, 0.25);
		}
	}
}

.etp-document-filters {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
        padding-bottom: 30px;    
    }
    
}
