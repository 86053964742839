@use './vars' as *;
@use './mixins' as *;
@use './utils';
@import url('./fonts.scss');

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	color: white;
	height: 100%;
	width: 100%;
	// overflow: hidden;

	margin: 0;
	font-family: 'Roboto';
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

html {
	background: $midnight_blue;
}

// #root {
// 	height: 100%;
// 	width: 100%;
// 	overflow-y: auto;
// }

.global {
	width: 100%;
}

.page-body {
	position: relative;
	background-color: transparent;

	.background-player {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;

		.lf-player-container {
			height: 100%;
		}
	}
}

* {
	color: inherit;
}

.language-selector-container {
	position: relative;

	.language-selector-dropdown {
		visibility: hidden;
		position: absolute;
		top: 100%;
		right: 0;
	}

	&.open {
		.language-selector-dropdown {
			visibility: visible;
			background: $midnight_blue;
			opacity: 0.9;
		}

		.language {
			padding: 10px 20px;
			display: flex;
			gap: 10px;
			cursor: pointer;

			font-family: 'Roboto';
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: 0.1em;
			text-transform: uppercase;

			&:hover {
				background-color: #7dc7f5;
				color: $midnight_blue;
			}
		}
	}
}

hr {
	border-style: solid;
	border-color: rgba($dark_blue, 0.25);
	border-bottom: 0 none;
}

.grid-line {
	content: '';
	background: rgba($dark_blue, 0.25);
	height: 1px;
	flex: 1;
}

.popup-modal {
	max-width: 90%;
	border: 1px solid white;
	background: rgba($midnight_blue, 0.9);
	color: white;
	// padding: 60px 30px;
	padding: 30px 0px;

	.modal-header {
		display: flex;
		justify-content: space-between;
	}

	@include media-breakpoint-up(lg) {
		max-width: 80%;
		padding: 60px 30px;
	}
}

@import url('../components//Layout/Layout.scss');
@import url('../components/Announcements/Announcements.scss');
@import url('../components/Articles/Articles.scss');
@import url('../components/BoxSets/BoxSets.scss');
@import url('../components/BrokerSelect/BrokerSelect.scss');
@import url('../components/Carousel/Carousel.scss');
@import url('../components/ContactForm/ContactForm.scss');
@import url('../components/DocumentList/DocumentList.scss');
@import url('../components/ETPList/ETPList.scss');
@import url('../components/ExchangeList/ExchangeList.scss');
@import url('../components/FairValueCalculation/FairValueCalculation.scss');
@import url('../components/FeaturedProducts/FeaturedProducts.scss');
@import url('../components/FeatureStatements/FeatureStatements.scss');
@import url('../components/Headline/Headline.scss');
@import url('../components/IndicesBenchmark/IndicesBenchmark.scss');
@import url('../components/Information/Information.scss');
@import url('../components/InlineTable/InlineTable.scss');
@import url('../components/InvestmentPortfolio/InvestmentPortfolio.scss');
@import url('../components/KeyInformation/KeyInformation.scss');
@import url('../components/knowledge/Knowledge.scss');
@import url('../components/KnowledgeBytes/KnowledgeBytes.scss');
@import url('../components/MarketPerformanceGraph/MarketPerformance.scss');
@import url('../components/Quote/Nasdaq/Nasdaq.scss');
@import url('../components/OfferingSummary/OfferingSummary.scss');
@import url('../components/OfferingSummary/Card.scss');
@import url('../components/Owl/Owl.scss');
@import url('../components/Paragraph/Paragraph.scss');
@import url('../components/PopupNotice/PopupNotice.scss');
@import url('../components/ProductAlert/ProductAlert.scss');
@import url('../components/Quote/Quote.scss');
@import url('../components/Scrollbar/Scrollbar.scss');
@import url('../components/StakingRewards/StakingRewards.scss');
@import url('../components/StructureDiagram/StructureDiagram.scss');
@import url('../components/TabStrip/TabStrip.scss');
@import url('../components/TwitterFeed/TwitterFeed.scss');
@import url('../components/VideoScroll/VideoScroll.scss');
@import url('../components/Volume/Volume.scss');

.grid-table {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	@include media-breakpoint-up(lg) {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.grid-headers,
	.grid-row {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		row-gap: 10px;

		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(8, minmax(0, 1fr));

			&:nth-child(even) {
				background-color: rgba($gray, 0.5);
			}
		}
	}

	.grid-headers {
		display: none;

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: repeat(8, minmax(0, 1fr));
		}
		.grid-header {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			padding: 30px;
			// border-bottom: 1px solid $midnight_blue;
			text-align: left;
		}
	}

	.grid-row {
		border-top: 1px solid $midnight_blue;
		padding: 40px 0;

		.grid-column {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}

		@include media-breakpoint-up(lg) {
			& > * {
				padding: 0 30px;
			}

			.grid-column {
				display: none;
			}
		}
	}
}

.widget,
.team {
	@include widget;
}

.row {
	margin-left: -20px;
	margin-right: -20px;

	& > * {
		padding-right: 20px;
		padding-left: 20px;
	}
}

.font-light {
	font-weight: 100;
}

#CookiebotWidget-buttons {
	background-color: hotpink;
}

.white,
.light,
.gray {
	.social-media {
		gap: 0;
		.image-link {
			height: 30px;
			padding: 0px 6px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: background-color 0.4s linear;

			path {
				transition: fill 0.4s linear;
			}
		}
		.image-link:hover {
			background-color: $midnight_blue;
			path {
				fill: white;
			}
		}
	}
}

.form {
	display: flex;
	row-gap: 25px;
	padding-top: 30px;

	@include media-breakpoint-up(lg) {
		padding-top: 60px;
	}

	.form-input {
		display: flex;
		flex-direction: column;
		gap: 15px;

		label {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		input,
		textarea {
			border: 1px solid white;
			border-radius: 2px;
			background-color: transparent;
			padding: 12px 16px;
			appearance: none;
			color: white;
			font-family: 'Roboto';
			font-size: 12px;
			line-height: 12px;

			&::placeholder {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: rgba(white, 0.8);
			}

			&:focus {
				background-color: white;
				color: $midnight_blue;
			}
		}

		.ms-Dropdown-title {
			line-height: normal;
		}
	}

	&.form-white {
		.form-input {
			input {
				border-color: $midnight_blue;
			}
		}
	}
}

.white,
.light {
	.form {
		.form-input {
			input,
			textarea {
				color: $midnight_blue;
				border-color: $midnight_blue;
			}
		}
	}
}

.action-link {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
	text-decoration: none;
	display: flex;
	gap: 10px;
	align-items: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.divider {
	border-top: 1px solid rgba($dark_blue, 0.25);
}

.text-light {
	font-weight: 400;
}

.text-lightest {
	font-weight: 100;
}

.timeline {
	@include widget;
}

.time-card {
	border-left: 0.5px solid #000000;
	padding-left: 40px;
	padding-right: 80px;
	display: flex;
	flex-direction: column;
}

.time-cards {
	display: flex;

	.time-card {
		padding: 40px;
		padding-top: 0px;
		flex: 1;
		gap: 20px;
	}
}

.transparent {
	.time-card-year {
		color: white;
		padding: 0;
	}
	.time-card {
		border-left-color: white;
	}

	.time-card-description {
		color: white;
	}
}

.time-card-title {
	white-space: pre-wrap;
}

.time-card-year {
	padding-bottom: 140px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	/* or 120% */

	/* CS midnight blue */

	color: #122444;
}

.time-card-description {
	color: #122444;
	white-space: pre-wrap;
}

.text-italic {
	font-style: italic;
}

.text-bold {
	font-weight: bold;
}

.text-underline {
	text-decoration: underline;
}

.pull-quote {
	@include klavika-light;

	margin: 0;
	color: white;
}

.light,
.white {
	.pull-quote {
		color: $midnight_blue;
	}
}

// .light,
// .white {
//     &+.white, &.light {
//         padding-top: 0px;
//     }
// }

.py-0 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.ms-Dropdown-container,
.ms-TextField {
	color: white;
	.ms-Label {
		color: white;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 12px;
		display: flex;
		align-items: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-bottom: 15px;
	}

	.ms-TextField-fieldGroup {
		border: 0 none;
		background-color: transparent;
		height: auto;
	}

	&.is-active {
		.ms-TextField-fieldGroup {
			&::after {
				border: 0 none;
			}
		}
	}

	.ms-Dropdown {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 10px;
		display: flex;
		align-items: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		min-width: 200px;

		.ms-Dropdown-title {
			background-color: transparent;
			border: 1px solid white;
			color: white;
			border-radius: 2px;
			padding: 12px 16px;
			padding-right: 56px;
			height: auto;
			width: 100%;

			&.ms-Dropdown-titleIsPlaceHolder {
				color: rgba(white, 0.8);
			}
		}

		.ms-Dropdown-caretDownWrapper {
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
			.ms-Dropdown-caretDown {
				color: white;
				font-weight: bold;
			}
		}

		&:focus {
			&::after {
				border-color: white;
			}

			.ms-Dropdown-caretDown {
				color: white;
			}
		}
	}

	&.error {
		.ms-Dropdown-title,
		.ms-TextField-field {
			border-color: $light_blue;
		}
	}
}

.ms-Overlay {
	background-color: rgba($midnight_blue, 0.6);
}

.ms-Callout,
.ms-Panel-contentInner {
	background-color: $midnight_blue;
	border: 1px solid white;
	border-top: 0 none;

	.ms-Dropdown-item {
		background-color: $midnight_blue;
		padding: 18px 12px 20px 30px;
		height: auto;

		&:not(:first-child) {
			border-top: 1px solid white;
		}

		.ms-Dropdown-optionText {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 10px;
			display: flex;
			align-items: center;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: white;
		}

		&:hover,
		&[class*=' dropdownItemSelected-'] {
			background-color: white;

			.ms-Dropdown-optionText {
				color: $midnight_blue;
			}
		}
	}
}

.ms-Panel-contentInner {
	.ms-Panel-content {
		padding: 0;
	}
}

.light,
.gray,
.white {
	.ms-Dropdown-container,
	.ms-TextField {
		color: $midnight_blue;
		.ms-Label {
			color: $midnight_blue;
		}
		.ms-Dropdown {
			.ms-Dropdown-title {
				color: $midnight_blue;
				border-color: $midnight_blue;
			}

			.ms-Dropdown-caretDownWrapper {
				.ms-Dropdown-caretDown {
					color: $midnight_blue;
				}
			}

			&:focus {
				&::after {
					border-color: $midnight_blue;
				}

				.ms-Dropdown-caretDown {
					color: $midnight_blue;
				}
			}
		}
	}
}

.collapse-paragraph-padding {
	p {
		margin: 0;
	}
}

@import url('./animations.scss');

.CybotCookiebotDialogActive {
	& > div:not(.CybotCookiebotDialogTabPanel):first-child {
		display: none;
	}

	#CybotCookiebotDialogHeader,
	#CybotCookiebotDialogPoweredByText,
	#CybotCookiebotDialogDetailFooter {
		display: none;
	}

	.CybotCookiebotDialogBodyButton {
		border-radius: 25px !important;
		font-family: 'Roboto' !important;
	}

	#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
	#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
	#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
		border-color: $mid_blue;
	}
}

#CybotCookiebotDialog *,
#CybotCookiebotDialogBodyUnderlay * {
	font-family: 'Roboto' !important;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Klavika' !important;
	}
}

#CybotCookiebotDialog {
	border-radius: 15px !important;
}

@media screen and (min-width: 1280px) {
	#CybotCookiebotDialog[data-template='bottom'] {
		top: calc(100% - 25px) !important;
		max-width: calc(100vw - 50px) !important;
		min-width: calc(100vw - 50px) !important;
	}
}

#CookiebotWidget {
	font-family: 'Roboto' !important;
	.CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
		fill: $mid_blue !important;
	}
	.CookiebotWidget-main-logo {
		display: none !important;
	}
	.CookiebotWidget-consent-details button {
		color: $mid_blue !important;
	}
	#CookiebotWidget-buttons {
		button {
			border-radius: 25px !important;
		}
		#CookiebotWidget-btn-change {
			background-color: $mid_blue !important;
			border-color: $mid_blue !important;
		}
	}
}

mark {
	background-color: transparent;
}

.video-container {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-bottom: calc(9 / 16 * 100%);

	.vimeo-video {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	.video-cover {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: white;
		width: 100%;
		height: 100%;

        .play-button {
            height: 118px;
            width: 118px;
            border-radius: 50%;
            background-color: white;
            border: 3px solid $midnight_blue;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &:before, &:after {
                position: absolute;
                left: calc(50% + 5px);
                top: 50%;
                translate: -50% -50%;
                content: " ";
                height: 30px;
                width: 3px;
                background-color: $midnight_blue;
                transition: background-color 0.3s linear;
                border-radius: 3px;
            }

            &:before {
                transform: rotateZ(45deg);
                top: calc(50% + 10px)
            }

            &:after { 
                transform: rotateZ(-45deg);
                top: calc(50% - 10px)
            }

            &:hover {
                background-color: $midnight_blue;
                &:before, &:after {
                    background-color: white;
                }
            }
        }
	}
}
