@use './vars' as *;

.light,
.white {
	color: $midnight_blue;
	background-color: white;
}

.gray {
	color: $midnight_blue;
	background-color: $gray;
}

.blue {
    background-color: $midnight_blue;
}

.bl-blue {
    border-left: 1px solid $midnight_blue;
}