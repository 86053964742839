@use '../../styles/vars' as *;

.fairvalue {
	display: flex;
	flex-direction: column;
	gap: 30px;

	.fairvalue-header,
	.fairvalue-unit {
		font-family: 'Klavika';
		font-style: normal;
		font-weight: 300;
		font-size: 40px;
		line-height: 48px;
	}

	.fairvalue-disclaimer {
		display: flex;
		flex-direction: column;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 21px;
	}

	.fairvalue-unit {
		display: flex;
		flex-direction: column;

		span + span {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		& + .fairvalue-unit {
			text-align: center;
			& + .fairvalue-unit {
				text-align: left;
			}
		}
	}

	.candle {
		border-top: 1px solid rgba($dark_blue, 0.25);
		margin-top: 30px;
		position: relative;

		.candle-value {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background-color: white;
			border: 1px solid rgba($dark_blue, 0.25);
			padding: 10px 20px;
			font-family: 'Klavika';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
		}
	}
}
