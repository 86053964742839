@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.document-list {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    // h5 {
    //     // padding-bottom: 40px;
    // }
	.document {
		text-decoration: none;
	}

	.row {
        row-gap: 0px !important;

        .document-content {
            display: flex;
            flex-wrap: wrap;
            .document-content-additional {
                padding-right: 30px;
                padding-bottom: 30px;
                width:100%;
                display: flex;
                flex-direction: column;
                row-gap: 30px;

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }
            .document-content-main {
                // display: flex;
                flex: 1;
            }
        }
        .documents {
            padding-bottom: 30px;
        }
		.document {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			align-items: center;
			letter-spacing: 0.1em;
			text-transform: uppercase;

            padding-top: 30px;
            padding-bottom: 30px;

            border-top: 1px solid rgba($dark_blue, 0.25);

            &.bottom {
                border-top: 0 none;
                border-bottom: 1px solid rgba($dark_blue, 0.25);
                padding-top: 20px;
                padding-bottom: 10px;
                text-transform: none;
            }

            display: flex;
            align-items: center;
            column-gap: 10px;
            
		}
	}
}
