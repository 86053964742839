@use '../../styles/mixins' as *;

@include media-breakpoint-up(xs) {
	.headline {
		padding-top: 60px;
		gap: 40px;
		display: flex;
		flex-direction: column;

		.button {
			display: inline-flex;
			width: auto;
			align-self: flex-start;
			justify-self: left;
		}

		h1 + .sub-header {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 300;
			font-size: 20px;
			line-height: 28px;
			margin-top: -15px;
		}

        .headline-actions {
            display: flex;
            padding-top: 20px;
            gap: 30px; 
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                .button {
                    align-self: flex-end;
                }
            }
        }
	}

	.breadcrumb {
		display: flex;
		text-decoration: none;
		gap: 12px;
		align-items: center;
		font-size: 10px;
		line-height: 12px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		margin-bottom: -20px;

        img {
            height: 15px;
        }

		@include media-breakpoint-up(lg) {
			font-size: 12px;
			line-height: 12px;

            img {
                height: 23px;
            }
		}

		
	}

    .headline-container {
        & + .gray, & + .white, & + .light {
            margin-top: 60px;
        }
    }
}
@include media-breakpoint-up(sm) {
	.headline {
		padding-top: 120px;
	}
    
    .headline-container {
        & + .gray, & + .white, & + .light {
            margin-top: 140px;
        }
    }
}


