@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.scrollbar {
	white-space: nowrap;
    height: auto !important;

	.scrollbar-view {
		overflow-y: hidden !important;
		position: static !important;
		padding-bottom: 60px;

		scrollbar-color: transparent transparent;
		&::-webkit-scrollbar {
			height: 0px;
            visibility: hidden;
		}
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0);
			box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
		}
		&::-webkit-scrollbar-thumb:window-inactive {
			background: rgba(0, 0, 0, 0);
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 80px;
			background: linear-gradient(270deg, white, transparent);
			width: 80px;
		}

		& > .scrollbar-view-container:after {
			content: '';
			display: inline-flex;
			min-width: 80px;
		}

		.swipe-right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			gap: 10px;
			position: absolute;
			bottom: 23px;
			right: 0;

			.swipe-icon {
				img:nth-child(2) {
					opacity: 0.2;
				}
				img:nth-child(3) {
					opacity: 0.1;
				}
			}

			&:after {
				display: none;
			}
		}

		@include media-breakpoint-up(lg) {
			padding-bottom: 80px;
			&:after {
				width: 120px;
			}
			& > *:after {
				min-width: 120px;
			}

			.swipe-right {
				display: none;
			}
		}
	}

	& > .scrollbar-track {
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		height: 3px;

		&::before {
			content: '';
			background-color: rgba($midnight_blue, 0.5);
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 3px;
			opacity: 1;
			border-top: 1px solid white;
			border-bottom: 1px solid white;
		}

		@include media-breakpoint-up(lg) {
			height: 30px;
			bottom: 0;

			&:before {
				height: 30px;
				border-top: 14.5px solid white;
				border-bottom: 14.5px solid white;
			}
		}
	}

	.scrollbar-thumb {
		color: white;
		display: inline-flex !important;
		justify-content: space-between;
		align-items: center;
		min-width: 60px;
		background-color: $midnight_blue;
		height: 3px;
		margin-bottom: 12px;

		svg {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			border-radius: 15px;
			margin-bottom: 0px;

			svg {
				display: inline-block;
				margin-left: 10px;
				margin-right: 10px;
				height: 16px;
				pointer-events: none;
			}
		}
	}

    &.hide {
        .scrollbar-view {
            padding-bottom: 0px;
        }
        .scrollbar-view:after, .scrollbar-track, .swipe-right {
            display: none;
        }
    }
}

.gray {
	.scrollbar {
		& > div:first-child:after {
			background: linear-gradient(270deg, $gray, transparent);
		}

		& > div:nth-child(2)::before {
			border-top-color: $gray;
			border-bottom-color: $gray;
		}
	}
}

.transparent, .blue, .knowledge-byte-detail-container {
	.scrollbar {
		& > div:first-child:after {
			background: linear-gradient(270deg, $midnight_blue, transparent);
		}
		& > div:nth-child(2) {
			&::before {
				background-color: white;
			}
		}

		.scrollbar-thumb {
			color: $midnight_blue;
			background-color: white;
		}

		& > div:nth-child(2)::before {
			border-top-color: $midnight_blue;
			border-bottom-color: $midnight_blue;
		}
	}
}
