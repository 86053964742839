@use './vars' as *;
@use './mixins' as *;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: 'Klavika';
	src: url('../fonts/klavika-bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Klavika';
	src: url('../fonts/klavika-medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Klavika';
	src: url('../fonts/klavika-light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Klavika';
	font-style: normal;
	font-weight: 400;
	margin: 0;
	letter-spacing: 0.03em;
	text-transform: uppercase;

	&.content {
		text-transform: none;
	}
}

.light,
.white,
.gray {
	h1,
	h2,
	h3,
	h4,
	h5 {
		color: $midnight_blue;
	}
}

@include media-breakpoint-up(xs) {
	h1 {
		font-weight: 500;
		font-size: 34px;
		line-height: 48px;

        &.article {
            text-transform: none;
        }
	}

	h2 {
		font-weight: 300;
		font-size: 30px;
		line-height: 42px;
	}

	h3 {
		font-weight: 300;
		font-size: 28px;
		line-height: 39px;
		letter-spacing: 0.02em;
	}

	h4 {
		font-weight: 500;
		font-size: 24px;
		line-height: 34px;
        text-transform: none;

		&.article {
			font-weight: 300;
			letter-spacing: 0.02em;
		}
	}

	h5 {
		font-weight: 500;
		font-size: 18px;
		line-height: 25px;
	}
}

@include media-breakpoint-up(md) {
	h1 {
		font-weight: 500;
		font-size: 56px;
		line-height: 68px;

		&.article {
			font-size: 45px;
			line-height: 54px;
		}
	}

	h2 {
		font-weight: 300;
		font-size: 42px;
		line-height: 50px;
	}

	h3 {
		font-size: 36px;
		line-height: 43px;
		letter-spacing: 0.03em;

        &.content {
            font-size: 38px;
            line-height: 46px;
            letter-spacing: 0.02em;
        }
	}

	h4 {
		font-size: 24px;
		line-height: 29px;

		&.article {
			font-size: 24px;
			line-height: 28px;
			text-transform: none;
		}
	}

    h5 {
        line-height: 22px;
        &.content {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@include media-breakpoint-up(xl) {
    // .roboto-light-intro {
    //     font-size: 20px;
    //     line-height: 28px;
    // }
}

@include media-breakpoint-up(xxl) {
	h1 {
		font-weight: 500;
		font-size: 60px;
		line-height: 72px;

		&.article {
			font-size: 50px;
			line-height: 60px;
		}
	}

    h2 {
        font-size: 45px;
        line-height: 54px;
    }

    h3 {
        font-size: 40px;
        line-height: 48px;
    }

    h4 {
        font-size: 26px;
        line-height: 32px;
    }
}


.page-content {
	@include klavika-light;
}

.body-intro {
	@include roboto-light-intro;
}

.uppercase {
	text-transform: uppercase;
}
