@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.tab-strip {
	@include widget;

	.center-header {
		display: flex;
		align-items: center;
		flex-direction: column;
		row-gap: 40px;
		> * {
			text-align: center;
			width: 55%;
		}
		p {
			margin: 0px;
		}
	}

    &.inline {
        padding: 0;
    }

	.tabs {
		margin-top: 80px;
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.tab-headers {
			display: flex;
			flex-direction: column;

			gap: 2px;
			.tab {
				display: none;
				cursor: pointer;
				text-align: center;
				text-transform: uppercase;

				background-color: $midnight_blue;
				justify-content: center;
				align-items: center;

				h5 {
					color: white;
				}

				flex: 1;
				padding: 20px;

				&.selected {
					background-color: white;
					h5 {
						color: $midnight_blue;
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			row-gap: 0;
			.tab-headers {
				flex-direction: row;
				.tab {
					display: flex;
					flex: 1;
				}

				.tab-dropdown {
					display: none;
				}
			}
		}

		.tab-content {
			padding: 40px;
			min-height: 100px;
			background-color: white;

			.tabs {
				margin-top: 0px;

				.tab-headers {
					justify-content: left;
					flex-direction: row;
					flex-wrap: wrap;
					.tab {
						display: inline-flex;
						flex: 0;
						white-space: nowrap;
						background-color: white;
						border-bottom: 5px solid transparent;
						padding-left: 0px;
						padding-right: 0px;
						margin-right: 80px;

						h5 {
							color: $midnight_blue;
						}

						&.selected {
							border-bottom-color: $midnight_blue;
						}
					}

					.tab-dropdown {
						display: none;
					}
				}

				.tab-content {
					border-top: 1px solid $gray;
					padding-left: 0px;
					padding-right: 0px;
				}
			}
		}
	}

	&.horizontal {
		padding: 0px;
		.tabs {
			margin-top: 0px;
			.tab-headers {
				.tab-dropdown {
					max-width: 250px;
				}
			}

			.tab-content {
				flex: 1;
			}

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				.tab-headers {
					flex-direction: column;
					row-gap: 2px;
					min-width: 250px;

					.tab {
						display: initial;
						flex: initial;
					}
				}
			}
		}
	}
}

.article-container {
    .tab-strip {
        .tabs {
            border: 1px solid $midnight_blue
        }
    }
}

.transparent,
.blue {
	.tab-strip {
		.tabs {
			background-color: rgba($midnight_blue, 0.8);
			
			.tab {
				background-color: white;
				h5 {
					color: $midnight_blue;
				}

				&.selected {
					background-color: transparent;
					h5 {
						color: white;
					}
				}
			}

			.tab-content {
				background-color: rgba($midnight_blue, 0.8);
                border: 3px solid white;
			}

            @include media-breakpoint-up(lg) {
				border: 3px solid white;

                .tab-content {
                    border: 0 none;
                }
			}
		}
	}
}

.gray .tab-strip .tab-content {
	.button {
		background-color: white;

		&:not(.link-button):hover {
			background-color: $midnight_blue;
			color: white;
		}
	}
}
