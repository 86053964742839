@use '../../styles/vars' as *;

h5 + table {
    padding-top: 10px;
}
table {
	width: 100%;
	border-spacing: 0;
    table-layout: fixed;

    
    &.complex-table {
        p {
            margin: 0;
        }
    }
    
	thead {
		tr th {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			padding: 30px;
			border-bottom: 1px solid $midnight_blue;

            text-align: left;
		}
	}

	tbody tr {
        &:nth-child(odd) {
			background-color: #f5f6f8;
		}
		td {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.05em;

            padding: 25px 30px;
		}

        &:last-child td {
			border-bottom: 1px solid $midnight_blue;
        }
	}

    &.striped-table {
        tbody tr td {
            border-bottom: 1px solid $midnight_blue;
        }

        tbody tr:last-child td {
            border-bottom-width: 0px;
        }
    }

    // &.no-white-space {
    //     thead tr th {
    //         white-space: nowrap;
    //     }
    // }
}
