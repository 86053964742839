@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.broker-modal {
    max-width: 90%;
    border: 1px solid white;
    background: rgba($midnight_blue, 0.9);
    color: white;
    padding: 25px 40px;

    .modal-header {
        display: flex;
        justify-content: space-between;
    }
    @include media-breakpoint-up(lg) {
        max-width: 80%;
    }
}

.broker-list {
    display: grid;
    column-gap: 50px;
    row-gap: 40px;
    padding-top: 50px;
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    img {
        max-width: 100%;
    }
}