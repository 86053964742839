@use '../../styles/mixins'  as *;

.information {
	@include widget;

    .paragraph {
        gap: 20px;
    }
	// h2 {
	// 	font-family: 'Klavika';
	// 	font-style: normal;
	// 	font-weight: 100;
	// 	font-size: 46px;
	// 	line-height: 55px;
	// 	/* or 120% */

	// 	letter-spacing: 0.02em;
	// }
}
