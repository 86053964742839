@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

h5 + .graph {
	margin-top: 40px;
}

.graph {
	padding: 40px 10px;
	background: rgba($midnight_blue, 0.8);
	border: 2px solid #ffffff;

	.paragraph {
		padding-right: 0;
	}

	&.market {
		padding: 40px;
	}

	@include media-breakpoint-up(lg) {
		padding: 80px 50px;
		margin-top: 40px;

		&.market {
			margin-top: -80px;
		}
	}
}

.indices {
	padding-top: 80px;
}

.indice {
	background: rgba($midnight_blue, 0.8);
	border: 1px solid rgba(255, 255, 255, 0.7);
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-decoration: none;
	width: 100%;
	transition: background-color 0.3s linear, border-radius 0.3s linear, color 0.3s linear;

	.indice-img.inverted {
		display: none;
	}

	h5 {
		padding-top: 10px;
	}

	img {
		max-width: 100%;
	}

	.indice-header {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 21px;
	}

	.indice-description {
		flex: 1;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 21px;
		padding-bottom: 30px;

		& + .indice-header {
			padding-bottom: 40px;
		}
	}

	.indice-content {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		display: flex;
		align-items: center;
		text-align: right;
	}

	.tag {
        transition: background-color 0.3s linear, border-color 0.3s linear, color 0.3s linear;
		background-color: white;
		color: $midnight_blue;
		align-self: flex-start;
        border: 1px solid white;

		& + .indice-header {
			padding-top: 40px;
		}
	}

	.more-info {
		position: relative;
		height: 50px;

		&::before,
		&::after {
			content: '';
			position: absolute;
            transition: border-color 0.3s linear;

		}

		&::before {
			top: 0;
			right: 0;
			border-left: 25px solid white;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
		}

		&::after {
			right: 2px;
			top: 2px;
			border-left: 23px solid $midnight_blue;
			border-top: 23px solid transparent;
			border-bottom: 23px solid transparent;
		}
	}

	&:hover {
		background-color: white;
		color: $midnight_blue;
		border-radius: 30px;
		@include media-breakpoint-up(lg) {
			border-radius: 40px;
		}
		cursor: pointer;

		.indice-img {
			display: none;

			&.inverted {
				display: initial;
			}
		}

		.tag {
			border-color: $midnight_blue;
		}

		.more-info {
			&::before {
				border-left-color: $midnight_blue;
			}
			&::after {
				border-left-color: white;
			}
		}

		.tick {
			line {
				stroke: rgba($dark_blue, 0.25) !important;
			}
		}
	}
}

.tooltip-line {
    opacity: 0;
}

.tooltip {
	position: absolute;
	height: 80px;
	pointer-events: none;
	white-space: nowrap;
	background-color: $light_blue;
	padding: 20px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 10px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 11.7088px;
	line-height: 15px;
	opacity: 0;
	color: white;

	.tooltip-y {
		color: black;
	}
}

.tooltip-container {
    position: absolute;
    pointer-events: none;

    .tooltip {
        position: relative;
        opacity: 1;
        width: 100%;
    }
}