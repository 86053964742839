@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.owl-container {
	background-color: $lighter_blue;

	.owl {
		margin-left: auto;
		margin-right: auto;
		padding: 80px 30px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 20px;
		align-items: flex-start;
		color: $midnight_blue;

        &>h5 {
            white-space: nowrap;
        }

        .contact-form {
            padding: 0;
        }

		.owl-description {
			display: flex;
			flex-direction: column;
			row-gap: 30px;
            flex: 1;
			p {
				margin: 0px;
				@include klavika-light;
			}

			.owl-question {
				padding-top: 30px;
				border-top: 1px solid $midnight_blue;
				display: flex;
				flex-direction: column;
				row-gap: 60px;
				align-items: flex-start;

                &.buttons {
                    flex-direction: row;
                    column-gap: 60px;
                    flex-wrap: wrap;
                }
			}
		}

		@include media-breakpoint-up(lg) {
			gap: 80px;
			flex-wrap: initial;
            flex-direction: row;
		}

		// Responsive containers that are 100% wide until a breakpoint
		@each $breakpoint, $container-max-width in $container-max-widths {
			@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
				max-width: $container-max-width;
			}
		}
	}

	.button {
		background-color: $lighter_blue;
		border-color: $midnight_blue;
		color: $midnight_blue;

		&:hover {
			color: $lighter_blue !important;
			background-color: $midnight_blue !important;
		}
	}

	&.alternate {
		background-color: $mid_blue;
		.owl {
			color: white;

            .owl-description {
                .owl-question {
                    border-top-color: white;

                    .button, .button:hover {
                        color: inherit !important;
                        background-color: transparent !important;
                    }
                }
            }

            .button {
                background-color: transparent;
                border-color: white;
                color: white;

                &:hover {
                    background-color: white !important;
                    color: $mid_blue !important;
                }
            }
		}
	}
}

.owl-choices {
	.ms-ChoiceField-field {
		display: flex;
		&:before,
		&:after {
			left: initial;
			right: 0px;
		}
		&:before {
			background-color: transparent;
			border: 2px solid $midnight_blue;
		}

		&:after {
			right: 5px;
		}

		&.is-checked:after {
			background: $midnight_blue;
			border-color: $midnight_blue;
		}

		.ms-ChoiceFieldLabel {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 16px;
			display: flex;
			align-items: center;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			padding-right: 26px;
			padding-left: 0px;
		}
	}
}


.slider {
    display: flex;
    width: 100%;
    .ms-Slider {
        flex: 1
    }
}

.ms-Slider-slideBox:hover, .ms-Slider-slideBox:active {
    .ms-Slider-thumb {
        border-color: $midnight_blue;
    }
    .ms-Slider-active {
        background-color: $midnight_blue;
    }
    .ms-Slider-inactive {
        background-color: white;
    }
}