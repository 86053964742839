
// Colors
$midnight_blue: #122444;
$gray: #ebedf0;
$light_blue: #7dc7f5;
$lighter_blue: #92CDF2;
$dark_blue: #1E2E3F;
$mid_blue: #525AE1;
$blue: #0E63DB;

// grid
$gutter: 40px;
$col-gutter: calc($gutter / 2);

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// breakpoint widths 
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;