@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.box-sets {
	@include widget;

	.boxset-info {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		padding-right: 40px;
		p {
			margin: 0;
		}

		.actions {
			padding-top: 20px;
		}
	}

	.boxsets-container {
		padding-bottom: 60px;
	}
	.boxsets-list {
		// padding-top: 60px;
		// padding-bottom: 60px;
		gap: 40px;
		display: flex;
		flex-wrap: nowrap;

		.boxset {
			padding: 40px;
			padding-top: 220px;
			padding-right: 60px;
			min-width: 300px;
			min-height: 600px;
			text-decoration: none;
			white-space: pre-wrap;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-repeat: no-repeat;
			background-size: cover;

			.boxset-title {
				z-index: 1;
				position: relative;
			}

            .boxset-description {
                @include klavika-lighter;
                display: none;
            }

			.boxset-forward {
				position: relative;
				align-self: end;
			}

			&:before {
				content: '';
				position: absolute;
				z-index: 0;
				top: 70px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				background: linear-gradient(194.03deg, rgba($midnight_blue, 0) 14.95%, $midnight_blue 80.37%);
			}
		}
        
	}

    .boxsets-container.layout_2 {
        .boxsets-list {
            .boxset {
                min-width: 400px;

                .boxset-description {
                    display: initial;
                }
            }
        }
    }
}

.boxset-header {
	position: relative;
	.boxset-image {
		position: absolute;
		inset: 0;
		left: 35%;
		top: 60px;
		width: 65%;
		z-index: -1;
		img {
			max-width: 100%;
		}
		&:before {
			content: '';
			position: absolute;
			inset: 0;
			background: linear-gradient(270.03deg, rgba(18, 36, 68, 0) 14.95%, $midnight_blue 80.37%);
			// transform: rotate(90deg);
		}
		&:after {
			content: '';
			position: absolute;
			inset: 0;
			background: linear-gradient(194.03deg, rgba(18, 36, 68, 0) 14.95%, $midnight_blue 80.37%);
		}
	}

	.boxset-share {
		position: absolute;
		right: 0px;
		text-align: right;
	}
}

.boxset-table-of-contents {
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	margin-top: 60px;
}

.boxset-navigation {
	display: flex;
	flex-direction: column;

	.boxset-navigation-item {
		display: flex;
		border-top: 1px solid rgba($dark_blue, 0.25);
		text-decoration: none;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding: 13px 0;
		gap: 30px;
		align-items: center;
		border-top: 1px solid rgba(255, 255, 255, 0.6);
		transition: background-color 0.3s linear, color 0.3s linear, padding 0.3s linear;

		.boxset-nav-index {
			font-weight: 500;
		}

		.boxset-nav-title {
			flex: 1;
		}

		&:hover {
			background: $midnight_blue;
			color: white;
			padding: 13px;
		}
	}
}

.boxset-row {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-template-areas: 'column row row';

	.boxset-column {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		grid-area: column;
		padding-right: 30px;
	}

	.boxset-rows {
		grid-area: row;
		grid-column: span 2;

		.boxset-row-item {
			padding-left: 40px;

			& + .boxset-row-item {
				padding-top: 40px;
				margin-top: 40px;
				border-top: 1px solid $midnight_blue;
			}
		}
	}

	&.border-left {
		.boxset-rows {
			border-left: 1px solid $midnight_blue;
		}
	}

	&.border-top {
		.boxset-rows {
			.boxset-row-item {
				&:before {
					content: '';
                    display: block;
					width: 100%;
					height: 1px;
					border-top: 1px solid $midnight_blue;
                    margin-bottom: 30px;
				}
			}
		}
	}
}

.boxset-evidence {
	display: grid;
	grid-template-columns: 1fr minmax(0, 2fr);
	column-gap: 40px;

	.boxset-evidence-info {
		row-gap: 40px;
		display: flex;
		flex-direction: column;

		ol,
		ul {
			margin: 0;
			padding: 0;

			li {
				margin-left: 20px !important;
			}
		}
	}

	.boxset-evidence-images {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		img {
			object-fit: contain;
			max-width: 100%;
		}

		.image-selector {
			display: flex;
			column-gap: 5px;
			button {
				all: unset;
				height: 14px;
				width: 21px;
				background-color: $gray;
				cursor: pointer;
				transition: background-color 0.3s ease;

				&.selected {
					background-color: $midnight_blue;
				}
			}
		}
	}
}

.boxset-contentsource {
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	.articles {
		padding: 0px;

		.boxset-articles {
			display: flex;
			column-gap: 40px;

			.article:not(h4) {
				width: 40%;
				.article-meta span + span:before {
					content: '|' !important;
				}

				.article.title {
					white-space: pre-wrap;
					border-bottom: 0 none;
					padding-bottom: 0;
				}

				&:hover {
					.article.title {
						.article-forward {
							display: none;
						}
					}
				}
			}

			&.left {
				.article:not(h4) {
					width: auto;
					display: grid;
					grid-template-areas:
						'image meta'
						'image title'
						'image tags';
					grid-template-rows: auto auto 1fr;
					grid-template-columns: 1fr auto;

					.article-img {
						grid-area: image;
						img {
							max-width: 480px;
						}
					}

					.article-meta {
						grid-area: meta;
					}

					.article.title {
						grid-area: title;
					}

					.article-tags {
						grid-area: tags;
						align-items: flex-start;
					}
				}
			}
		}
	}
}

.blue,
.transparent {
	.boxset-rows {
		border-left: 1px solid white;
	}

	.boxset-contentsource {
		.article {
			.article-tags {
				.tag {
					background-color: white;
					color: $midnight_blue;
				}
			}
		}
	}
}


.pie-breakdown-table {
	border-collapse: collapse;
	thead tr th,
	tbody tr td {
		padding: 0 15px 15px 15px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		vertical-align: top;
	}

    tbody tr:last-child td {
        border-bottom: 0 none;
    }

	tbody tr td {
		padding-top: 15px;
		transition: ease 0.3s color;

		.circle-legend {
			min-width: 17px;
			height: 17px;
			border-radius: 50%;
			display: inline-block;
		}

		.asset {
			transition: ease 0.3s color;
		}
	}

	thead tr,
	tbody tr {
		background-color: transparent !important;
		transition: ease 0.3s background-color, ease 0.3s color;
	}

	tbody tr {
		cursor: pointer;

		&:hover,
		&.selected {
			color: white;
			background-color: $midnight_blue !important;

			.asset {
				color: white;
			}
		}
	}
}

.donut-graph-container {
	position: relative;
	padding-bottom: 100%;

	.donut-graph {
		position: absolute;
	}

	.donut-graph-header {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 140px;

		text-align: center;

		display: flex;
		flex-direction: column;
		row-gap: 5px;

		.title {
			font-style: normal;
			font-weight: 700;
			font-size: 30px;
			line-height: 25px;
		}
	}
}
