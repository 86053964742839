@keyframes animate-ease-up {
    0% { 
        transform: translateY(50px);
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}

.animate-ease-up {
    animation: animate-ease-up 0.5s linear;
    transform: translateY(0);
    opacity: 1;
}


@keyframes animate-ease-left {
    0% { 
        transform: translateX(50px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }
}

.animate-ease-left {
    animation: animate-ease-left 0.5s linear;
    transform: translateX(0);
    opacity: 1;
}
