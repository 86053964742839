@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.investment-portfolio {
	@include media-breakpoint-up(lg) {
		border-left: 1px solid $midnight_blue;

		.portfolio-cards {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}

.investment-portfolio-categories-dropdown {
	display: flex;
	padding-bottom: 25px;

	.ms-Dropdown-container {
		width: 100%;

		@include media-breakpoint-up(sm) {
			width: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.investment-portfolio-categories {
	display: none;
	flex-direction: row;
	justify-content: flex-start;
	padding: 30px 30px 30px 30px;
	margin: 0px -30px;

	.button {
		flex: 0;
		border-width: 1px;
		cursor: pointer;
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		margin: 0px -15px;
		column-gap: 10px;
		padding: 50px 60px;
	}
}

.investment-portfolio-info {
	padding-top: 80px;
	display: flex;
	flex-direction: column;
    position: sticky;
    top: 0px;
	row-gap: 40px;
	@include media-breakpoint-up(lg) {
		padding-bottom: 80px;
	}

	h3 {
		padding-top: 20px;
		padding-right: 60px;
	}

	p {
		margin: 0;
	}
}

.investment-portfolio-investment {
	padding: 70px 0;

	@include media-breakpoint-up(lg) {
		padding: 70px 45px;
	}
}

.investment-portfolio-header {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: $midnight_blue;
}

.portfolio-cards {
	display: grid;
	padding: 0 !important;
	border-left: 0 none;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	box-sizing: border-box;
	gap: 1px;

	@include media-breakpoint-up(sm) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	.portfolio-card {
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		padding: 30px;
		background: white;
		box-shadow: 0 0 0 1px $midnight_blue;
		cursor: pointer;

		.portfolio-title {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 21px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.01em;
			text-transform: uppercase;
			color: $midnight_blue;
		}

		.portfolio-item {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			text-align: center;
			color: $midnight_blue;
		}

		.portfolio-img {
			max-width: 100%;
			height: 101px;
            padding-top: 25px;
            padding-bottom: 25px;
		}
	}

	.show-more-portfolio {
		background-color: $midnight_blue;
		box-shadow: 0 0 0 1px $midnight_blue;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		padding: 50px 30px;

		span {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 21px;
		}
	}
}

.investment-portfolio {
	position: relative;
	padding: 0 !important;
}

.modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: $gray;
		opacity: 0.95;
	}

	.modal-popup {
		background-color: white;
        width: 80%;
        @include media-breakpoint-up(lg) {
		    width: 60%;
        }
		height: auto;
		max-height: 80%;
		z-index: 1;
		padding: 60px;

		.modal-header {
			display: flex;
			justify-content: space-between;

			img {
				height: 80px;
			}
		}

		.modal-close:after {
			display: inline-block;
			content: '\00d7'; /* This will render the 'X' */
			cursor: pointer;
			font-size: 25px;
		}

		.modal-body {
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding-bottom: 40px;
		}

		.modal-footer {
			display: flex;
			gap: 20px;
		}
	}
}

.portfolio-link {
	font-family: 'Klavika';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height, or 120% */

	letter-spacing: 0.03em;
}

.tag {
	background-color: $midnight_blue;
	color: white;
	padding: 7px 12px 6px;
	border-radius: 20px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 10px;
	/* identical to box height, or 100% */

	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;

	display: inline-block;

    &.viewed {
        background-color: $blue;
    }
}
