@use '../../styles/vars' as *;

.button {
	color: white;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	align-items: center;

	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	display: inline-flex;
	gap: 18px;

	color: #ffffff;
	background-color: $midnight_blue;
	justify-content: center;

	text-decoration: none;
	border: 3px solid #ffffff;
	border-radius: 30px;
	padding: 20px 30px;
	cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }

	transition: background-color 0.3s linear, color 0.3s linear;

	img {
		height: 15px;
	}

	&:not(.link-button):hover {
		background-color: white;
		color: $midnight_blue;
	}

	&.secondary {
		border: 2px dashed #ffffff;
	}

	&.link-button {
		border: 0 none;
		padding: 0px;
		gap: 10px;

		span {
			white-space: nowrap;
		}
	}
}

.icon-button {
    background: transparent;
    border: 0 none;
    color: white;
    cursor: pointer;
}

.light,
.gray,
.white {
	.button {
		border-color: $midnight_blue;
		color: $midnight_blue;
		background-color: white;

		&.gray {
			border-color: rgba($dark_blue, 0.25);
		}

		&:not(.link-button):hover {
			background-color: $midnight_blue;
			color: white;
		}

        .button-icon-dark {
            display: none;
        }
    
        .button-icon-light {
            display: initial;
        }
    
        &:not(.link-button):hover {
            .button-icon-dark {
                display: initial;
            }
    
            .button-icon-light {
                display: none;
            }
        }
	}

	.button.primary {
		background-color: $midnight_blue;
		color: white;
	}

	
}

.transparent {
	.button.primary {
		background-color: white;
		color: $midnight_blue;
	}

    .button {
        .button-icon-dark {
            display: initial;
        }
    
        .button-icon-light {
            display: none;
        }
    
        &:not(.link-button):hover {
            .button-icon-dark {
                display: none;
            }
    
            .button-icon-light {
                display: initial;
            }
        }
    }

	
}

.gray {
	.button {
		background-color: $gray;
	}
}


.button-strip {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid rgba($dark_blue, 0.25);
    flex-wrap: wrap;
}