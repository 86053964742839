@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

@include media-breakpoint-up(xs) {
	.card {
		padding: 50px 30px 30px 30px;
        opacity: 0;
	}
}

// @include media-breakpoint-up(sm) {
// 	.card {
// 		margin-top: 40px;
// 	}
// }

.card {
	border: 2px solid white;
	cursor: pointer;
	background: rgba($midnight_blue, 0.7);
	display: flex;
	gap: 40px;
	text-decoration: none;
    transition: background-color 0.3s linear, border-radius 0.3s linear, color 0.3s linear;

	.card-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 5px;

		.card-amount-title {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
			height: 30px;
			display: flex;
			align-items: flex-end;
			letter-spacing: 0.01em;
            text-transform: uppercase;
		}

		.card-amount {
            flex: 1;
            min-height: 80px;
			font-family: 'Klavika';
			font-style: normal;
			font-weight: 500;
			font-size: 23px;
			line-height: 30px;
			text-transform: uppercase;
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
			align-content: baseline;
		}

		.card-amount-unit {
			font-weight: 100;
		}

		.more-info {
			position: relative;
			height: 28px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
			&::before,
			&::after {
				content: '';
				position: absolute;
                transition: border-color 0.3s linear, color 0.3s linear;
			}

			&::before {
				top: 0;
				right: 0;
				border-left: 14px solid white;
				border-top: 14px solid transparent;
				border-bottom: 14px solid transparent;
			}

			&::after {
				right: 2px;
				top: 2px;
				border-left: 12px solid $midnight_blue;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
			}
		}
	}

	&.no-hover {
		padding: 40px;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

		.card-content {
			flex: 1;
			position: relative;
		}

		.card-amount-title {
			height: auto;
			text-transform: uppercase;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
		}

		.card-amount {
			height: auto;
            min-height: auto;
            flex: 1;
			font-family: 'Klavika';
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;

			@include media-breakpoint-up(lg) {
				font-size: 32px;
				line-height: 38px;
			}
		}

		.card-forward {
			position: absolute;
			right: 0;
			bottom: 0;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            left: 0px;
			img {
				height: 32px;
			}
		}
	}

	.card-description {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
        font-size: 15px;
        line-height: 21px;
		flex: 1;
		padding-bottom: 30px;
		padding-top: 30px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.6);
		margin-bottom: 16px;
	}

	&:hover:not(.no-hover) {
		background: white;
		color: $midnight_blue;
		border-radius: 40px;

		.more-info {
			&::before {
				border-left-color: $midnight_blue;
			}
			&::after {
				border-left-color: white;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.card {
		padding: 60px 40px 40px 40px;

		.card-content {
			.card-amount-title {
				font-weight: 600;
				font-size: 12px;
				line-height: 12px;
				min-height: 30px;
				display: flex;
				align-items: flex-end;
				letter-spacing: 0.03em;
			}
		}
	}
}
