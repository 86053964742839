@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.key-information-title {
	display: flex;

    flex-direction: column;
    row-gap: 25px;

    @include media-breakpoint-up(xl) {
        flex-direction: row;
	align-items: center;
	justify-content: space-between;
    }

	.key-information-dropdown {
		display: flex;
		padding-bottom: 25px;

		.ms-Dropdown-container {
			width: 100%;

			@include media-breakpoint-up(sm) {
				width: auto;
			}
		}

		@include media-breakpoint-up(xl) {
			display: none;
		}
	}
	.key-information-selectors {
		display: none;
		gap: 20px;

		@include media-breakpoint-up(xl) {
			display: flex;
		}

		.button {
			border-width: 1px;

			&.selected {
				background-color: $midnight_blue;
				color: white;
			}
		}
	}
}

.key-information-content {
	padding-top: 40px;

	.document-link {
		margin-top: 60px;
	}
}
