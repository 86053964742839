@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.product-alert-container {
	@include widget;


	.product-alert {
		background-color: $mid_blue;

		a {
			background-color: transparent;
		}

		.product-alert-detail {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
			p {
				margin: 0px;
				@include roboto;
			}

            .svg-inline--fa {
                font-size: 1.3em;
            }
		}

        @include media-breakpoint-up(xs) {
			padding: 35px 25px 25px 35px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 20px;
			align-items: flex-start;
        }

        @include media-breakpoint-up(lg) {
            gap: 80px;
            padding: 30px;
            padding-top: 23px;
            padding-bottom: 23px;
        }
	}

	.links {
		display: flex;
		gap: 30px;
        row-gap: 10px;
		flex-wrap: wrap;
	}
}


.product-alert-container {
    padding-top: 0px;
}