@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.quote-container {
	@include widget;

	.quote {
		@include klavika-lighter;
	}

	@include media-breakpoint-up(xs) {
		.quote {
			padding-left: 20px;
			border-left: 1px solid white;
			// margin-bottom: 60px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			@include media-breakpoint-up(lg) {
				gap: 40px;
			}

			p {
				margin: 0;
			}

			.quote-extra {
				.investment {
					display: flex;
					flex-direction: column;
					gap: 5px;
					@include media-breakpoint-up(lg) {
						gap: 25px;
					}
					.investment-header {
						font-family: 'Klavika';
						font-style: normal;
						font-weight: 700;
						font-size: 11px;
						line-height: 18px;
						letter-spacing: 0.1em;
						@include media-breakpoint-up(lg) {
							font-size: 20px;
							line-height: 24px;
							letter-spacing: 0.03em;
						}
						text-transform: uppercase;
					}

					.investment-amount {
						font-family: 'Klavika';
						font-style: normal;
						font-weight: 400;
						font-size: 24px;
						line-height: 29px;

						@include media-breakpoint-up(lg) {
							font-size: 55px;
							line-height: 52px;
						}

						.investment-amount-currency {
							font-weight: 100;
						}
					}
				}
			}

			& + .links {
				margin-top: 30px;
				margin-left: 40px;
			}

			& + .quote-actions {
				margin-top: 40px;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.quote {
			padding-left: 40px;
			border-left: 3px solid white;
		}
	}

	@include media-breakpoint-up(lg) {
		.quote-actions {
			margin-left: 40px;
		}

		.col-lg-6 + .col-lg-6 {
			.quote {
				margin-left: -40px;
			}

			.quote-actions {
				margin-left: 0px;
			}
		}
	}
	.links {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
	}
}

.white,
.light {
	.quote-container {
		.quote {
			border-color: $midnight_blue;
		}
	}
}
