.volume-container {
	position: relative;
    padding-bottom: 480px;
    border-top: 1px solid #fff;
	.volume {
		position: absolute;
        top: 0;
        left: 0;
        right: 0;
		display: flex;
        padding-bottom: 100%;
		flex-direction: column;
		gap: 10px;

        opacity: 0;

        &.animate-ease-left {
            opacity: 1;
        }

		.volume-title {
			padding-top: 80px;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: 20px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
		}
	}
}
