@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.announcements {
	@include widget;

	.announcements-row {
		display: flex;
        align-items: flex-start;

		& > * {
			width: 100%;
            min-width: 100%;

            .article.title {
                white-space: normal;
            }
		}
	}

	@include media-breakpoint-up(sm) {
		.announcements-row {
			& > * {
				width: 50%;
                min-width: 50%;
			}
		}
	}
	// .articles.row {
	// 	// margin-left
	// 	@include media-breakpoint-up(sm) {
	// 		& > * {
	// 			padding-left: 0px !important;
	// 			padding-right: 0px !important;
	// 		}
	// 	}

	// 	@include media-breakpoint-up(lg) {
	// 		margin-left: -30px !important;
	// 		margin-right: -30px !important;
	// 	}
	// }

	@include media-breakpoint-up(lg) {
		.articles {
			padding-top: 80px;
		}

		.announcements-row {
            margin-right: 80px;
			& > * {
				width: calc(100% / 3);
                min-width: calc(100% / 3);
			}
		}
	}
	// .articles {
	// 	padding-top: 40px;
	// 	padding-bottom: 0px;
	// 	gap: 0px !important;

	// 	& > div > .article {
	// 		.article.title {
	// 			display: flex;
	// 			flex-direction: column;
	// 			gap: 20px;
	//             margin-bottom:50px;
	// 		}
	// 	}

	// 	@include media-breakpoint-up(lg) {
	// 		& > div:first-child {
	// 			margin-left: -20px;
	// 		}

	// 		& > div:last-child {
	// 			margin-right: -20px;
	// 		}
	// 		& > div > .article {
	// 			padding: 40px;
	// 			margin-left: -20px;
	// 			margin-right: -20px;
	//             .article.title {
	//                 margin-bottom: 10px;
	//             }
	// 		}

	// 		& > div:nth-child(even) > .article {
	// 			background-color: $gray;

	// 			.article-forward {
	// 				background-color: $gray !important;
	// 			}
	// 		}
	// 	}
	// }
}
